import React, { useState } from "react"
import { graphql, PageProps } from "gatsby"
import { Send, Mail, Phone, MapPin, Loader } from "react-feather"

import { TextInput, Button } from "./../components/ui"
import Layout from "../components/layout"
// import { Form, Description } from "../components/contact"
// import { ContactQuery } from "./__generated__/ContactQuery"

export default ({ location }) => {
    // const api_url = data.site.siteMetadata.newsletter.api_url;
    const hasContactForm = true
    return (
        <Layout
            seo={{
                title: "Newsletter",
            }}
            location={location}
        >
            <div className="container mx-auto py-12">
                <div className="title py-12 text-center">
                    <h2 className="font-black text-5xl text-color-1">
                        Newsletter
                    </h2>
                </div>
                <div className="flex flex-wrap pb-40">
                    {
                        <div className="w-full lg:w-1/2 px-6">
                            <Form />
                        </div>
                    }
                    <div
                        className={`w-full ${
                            hasContactForm ? "lg:w-1/2" : "lg:w-2/3 mx-auto"
                        } px-6 pt-8`}
                    >
                        {/*<Description data={data.site.siteMetadata.contact} />*/}
                        <div>
                            {
                                <p className="text-color-default">
                                    Subsribe to my newsletter
                                </p>
                            }
                        </div>
                        <br />
                        <br />
                        <div>
                            <p style={{ fontSize: "xx-small" }}>
                                Form will be submitted in new tab
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

const Form = () => {
    const [data, changeData] = useState({
        email: "",
    })

    const updateData = v => changeData({ ...data, ...v })

    return (
        <form
            method="post"
            action="https://tinyletter.com/PWS-Elemental-Demo"
            rel="noopener noreferrer"
            target="_blank"
        >
            <TextInput
                label="Email"
                name="email"
                type="email"
                required="required"
                onChange={e =>
                    updateData({
                        email: e.target.value,
                    })
                }
            />
            <div className="py-3 lg:p-4">
                <Button
                    type="button,submit"
                    title="Submit"
                    iconRight={<Send />}
                />
            </div>
        </form>
    )
}
